/*
 * Admin Sidebar Styles
 */

@sidebar-bg-color         : @gray-dark;
@sidebar-divider-color    : lighten(@sidebar-bg-color, 5%);

@sidebar-link-color       : @gray-light;
@sidebar-link-hover-color : #fff;
@sidebar-link-hover-bg    : darken(@sidebar-bg-color, 4%);
@sidebar-link-active-color: #fff;
@sidebar-link-active-bg   : @sidebar-link-hover-bg;

@sidebar-header-color     : @brand-primary;

// Responsive Sidebar
// --------------------------------------------------------------------- //
.sidebar {
    display: none;
}

.sidebar.open {
    display: block;
    position: absolute;
    width: 100%;
    z-index: 3;
    background: @sidebar-bg-color;
    margin: 0px;
    padding: 0 20px 0;
}

@media (min-width: 768px) {
    .sidebar {
        background-color: @sidebar-bg-color;
        display: flex;
        flex-flow: column nowrap;
        bottom: 0;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 20px;
        position: absolute;
        width: @sidebar-width;
        top: @navbar-height;
        z-index: 1000;
        .links {
            flex: 1 0 auto;
        }
    }
}

// Headings
// --------------------------------------------------------------------- //
.sidebar h3 {
    color: @sidebar-header-color;
    font-size: 14px;
    background: darken(@gray-dark, 6%);
    margin: 0 -20px 0;
    padding: 15px 20px;
    text-transform: uppercase;
}

.sub-header {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
}

// Sidebar Nav Items
// --------------------------------------------------------------------- //
.nav-sidebar {
    margin-bottom: 0;
    margin-left: -20px;
    margin-right: -21px;
    border-top: 1px solid @sidebar-divider-color;
}

.nav-sidebar > li > a {
    padding-left: 20px;
    padding-right: 20px;
    background-color: transparent;
    color: @sidebar-link-color;
    font-size: 14px;
    font-weight: 400;
    border-bottom: 1px solid @sidebar-divider-color;
    &:hover,
    &:focus {
        background-color: @sidebar-link-hover-bg;
        color: #fff;
    }
    .badge {
        .icon-base(20px);
        background: @brand-danger;
        border-color: @brand-danger;
        position: absolute;
        right: 12px;
    }
}

.nav-sidebar > li.active > a {
    background-color: @sidebar-link-active-bg;
    color: @sidebar-link-active-color;
    &::after {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        right: 0;
        z-index: 2;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-right-color: @body-bg;
        content: '';
        //margin-right: -20px;
    }
}

// Subnavs
// --------------------------------------------------------------------- //
.subnav li {
    margin: 0;
    padding: 0;
}

.subnav li a:before {
    content: '----\00a0\00a0';
}

.subnav li a {
    color: @sidebar-link-color !important;
    display: block;
    padding: 8px 10px 8px 0;
    margin: 0;
    color: gray;
    border-left: 1px dashed @sidebar-link-color;
    margin-left: 35px;
}

.subnav li a:hover {
    text-decoration: none;
    color: @sidebar-link-hover-color !important;
    background: @sidebar-link-hover-bg !important;
}

.subnav li.active a {
    color: @sidebar-link-active-color !important;
    &::after {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        right: 0;
        z-index: 2;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-right-color: @body-bg;
        content: '';
        //margin-right: -20px;
    }
}

// Footer / Copyright
// --------------------------------------------------------------------- //
.sidebar-footer {
    text-align: center;
    padding: 20px;
}

.copyright {
    color: @gray;
}
