/*
 * "Cards" are like simple panels.
 * We will use panel defaults as a starting place
 */

.card {
    background: @panel-bg;
    padding: @panel-body-padding !important;
    margin-bottom: @line-height-computed;
    border: 1px solid @panel-default-border;
    border-radius: @panel-border-radius;
    .clearfix();
    & > *:first-child {
        margin-top: 0;
        padding-top: 0;
    }
    & > *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}
