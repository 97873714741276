/*
 * Admin Header Styles
 */

@user-nav-open-bg : lighten(@gray-lighter, 2%);

// Top Navbar
// --------------------------------------------------------------------- //
.navbar {
    border-bottom: none;
}

.sidebar-toggle-container {
    float: left;
    margin: 20px 0 0 15px;
}

.sidebar-toggle {
    font-size: 13px;
}

// User/Profile Menu
// --------------------------------------------------------------------- //
.user-nav {
    .dropdown {
        padding: 0;
        .avatar {
            width: 40px;
            height: 40px;
        }
        & > a {
            position: relative;
            min-height: @navbar-height;
            padding: 12px;
            &:after {
                content: "\f107";
                position: absolute;
                *margin-right: .3em;
                right: 10px;
                top: 22px;
                .fa();
            }
        }
        .profile-info {
            display: inline-block;
            vertical-align: middle;
            padding: 0 20px 0 10px;
            line-height: 15px;
            small {
                display: block;
                opacity: .5;
            }
        }
        .dropdown-menu {
            min-width: 100%;
        }
    }
    .dropdown.open {
        & > a {
            background: @user-nav-open-bg;
        }
        & > a:after {
            top: 16px;
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }
        .dropdown-menu {
            border-top: none;
            padding-top: 15px;
            &::after {
                .arrow-down(@user-nav-open-bg, 8px);
                top: 0;
            }
        }
    }
}
