/*
 * Master Page Layout
 */

.page-wrap {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: mix(@gray-lighter, #fafafa);
    position: relative;
}

.page-content {
    flex-grow: 1;
    padding-top: @navbar-height;
    padding-bottom: @line-height-computed * 2;
}
