/*
 * Admin Global Styles
 */

@sidebar-width: 250px;
// Typography
// --------------------------------------------------------------------- //
.page-header {
    margin-top: 0;
}

// Forms
// --------------------------------------------------------------------- //
.input-group {
    &.date,
    &.time,
    &.datetime {
        max-width: 200px;
    }
}

.form-actions {
    margin-top: @line-height-computed * 1.5;
}

.input-search {
    background: @gray-lighter;
}

// Cards
// --------------------------------------------------------------------- //

.card {
    header {
        .margin-bottom-double;
        h3 {
            .no-margin();
            line-height: 34px;
        }
    }
}

.card-small {
    width: 400px;
}

.card-narrow {
    width: 550px;
}

.card-med {
    width: 700px;
}

.card-wide {
    width: 850px;
}

.index.card {
    .table-responsive();
}

// Main Content Area
// --------------------------------------------------------------------- //
.main {
    padding: @grid-gutter-width;
    @media (min-width: @screen-sm) {
        & {
            margin-left: @sidebar-width;
        }
    }
    &.narrow {
        max-width: 1200 - @sidebar-width;
    }
    &.wide {
        max-width: 1600 - @sidebar-width;
    }
}
